import sanityClient from "@sanity/client";
import { LanguagesEnum } from "./types";

export const client = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  apiVersion: process.env.REACT_APP_SANITY_API_VERSION,
  token: "",
  useCdn: process.env.NODE_ENV === "production",
});

export type Slug = {
  _type: "slug";
  current: "string";
};

export type FieldWithTranslation<FieldType = string> = {
  [LanguagesEnum.RU]: FieldType;
  [LanguagesEnum.EN]: FieldType;
  [LanguagesEnum.ZH]?: FieldType;
};
