import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "./i18n";
import "animate.css";
import "./styles";

const render = () => {
  const root = document.getElementById("root");

  if (process.env.NODE_ENV === "production") {
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      root
    );
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>,
      root
    );
  }
};

render();
