import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "./button";

export const EmptyResult: React.FC = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  return (
    <div className="flex flex-col m-auto">
      <p className="font-bold text-2xl sm:text-4xl">{t("empty")}</p>
      <Button size="l" onClick={() => navigate(-1)} className="mx-auto mt-5">
        {t("back")}
      </Button>
    </div>
  );
};
