import React from "react";
import { useQuery } from "react-query";
import { EmptyResult } from "../../shared/empty-result";
import { Loader } from "../../shared/loader";
import { NetworkError } from "../../shared/network-error";
import { fetchMuseums } from "./api";
import { Museum } from "./museum";

export const Museums: React.FC = () => {
  const { isFetching, isError, data, refetch } = useQuery(
    "museums",
    fetchMuseums
  );

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return <NetworkError handleError={refetch} />;
  }

  if (data!.length === 0) {
    return <EmptyResult />;
  }

  return <Museum urls={data!.map((museum) => museum.url)} />;
};
