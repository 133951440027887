import ym from "react-yandex-metrika";
import { TOrderType } from "../types";

type TEvents = {
  [key in TOrderType]: {
    request: YaEventTypes | "";
    accept: YaEventTypes | "";
  };
};

export enum YaEventTypes {
  "restaurant-request" = "book-restaurant",
  "restaurant-accept" = "accept-restaurant",
  "shop-request" = "shop-book",
  "shop-accept" = "shop-accept",
  "service-book" = "service-book",
  "service-accept" = "service-accept",
}

export const events: TEvents = {
  restaurant: {
    request: YaEventTypes["restaurant-request"],
    accept: YaEventTypes["restaurant-accept"],
  },
  shop: {
    request: YaEventTypes["shop-request"],
    accept: YaEventTypes["shop-accept"],
  },
  activity: {
    request: "",
    accept: "",
  },
  service: {
    request: YaEventTypes["service-book"],
    accept: YaEventTypes["service-accept"],
  },
};

export const sendUrlChangeEvent = (url: string) => {
  ym("hit", url);
};

export const sendEvent = (
  event: "" | YaEventTypes,
  options?: { [key: string]: any }
) => {
  if (event !== "") {
    ym("reachGoal", event, options);
  }
};
