import React from "react";
import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import { Loader } from "../../shared/loader";
import { getIdsFromUrls } from "../lib/url";

interface IProps {
  urls: string[];
}

export const Excursion: React.FC<IProps> = ({ urls }) => {
  const { i18n } = useTranslation();
  const [load, setLoad] = React.useState(false);
  return (
    <>
      {!load && <Loader />}
      <Iframe
        onLoad={() => setLoad(true)}
        title="a"
        id="tours-box-v2-widget-iframe"
        data-with-logo="false"
        url={`https://www.sputnik8.com/w/v2_tours_box?locale=${
          i18n.language
        }&query=&affiliate_id=4084&limit=20&pages=1&lead_text=0&disable_logo=1&transparent=1&no_borders=1&ssl=1&toursByIds=${getIdsFromUrls(
          urls
        )}&horizontal=1&autoheight=1&all_btn=1&show_top=0&show_cities_dropdown=0&display_ad_label=1`}
        width="100%"
        height="100%"
        frameBorder={0}
        scrolling="auto"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </>
  );
};
