import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import resources from "./resources";
import { DEFAULT_LANGUAGE } from "./constants";
import Cookies from "js-cookie";

const cookieLanguage = Cookies.get("language");

i18n.use(initReactI18next).init({
  resources,
  lng: cookieLanguage || DEFAULT_LANGUAGE,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
