import React from "react";
import ReactDropDown, { ReactDropdownProps } from "react-dropdown";
import { IoMdArrowDropdown } from "react-icons/io";

interface IProps extends ReactDropdownProps {
  label?: string;
}

export const DropDown: React.FC<IProps> = ({ label, ...props }) => {
  return (
    <div>
      <p className="block mb-1 font-bold text-lg">{label}</p>
      <ReactDropDown
        {...props}
        className="dropdown"
        controlClassName="dropdown__control"
        menuClassName="dropdown__menu"
        placeholderClassName="dropdown__placeholder"
        arrowOpen={
          <div className="dropdown__arrow dropdown__arrow_open">
            <IoMdArrowDropdown />
          </div>
        }
        arrowClosed={
          <div className="dropdown__arrow dropdown__arrow_closed">
            <IoMdArrowDropdown />
          </div>
        }
      />
    </div>
  );
};
