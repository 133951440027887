import React from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "../shared/loader";

export const ChatPage: React.FC = () => {
  const { i18n } = useTranslation();
  const [load, setLoad] = React.useState(false);

  const url =
    i18n.language === "ru"
      ? "https://jivo.chat/9cj8tyYPYR"
      : "https://jivo.chat/luJ3lc8WX4";
  return (
    <>
      <div className="fixed top-0 left-0 bottom-0 right-0 h-full w-full">
        {!load && <Loader />}
        <iframe
          src={url}
          title="chat"
          width="100%"
          height="100%"
          onLoad={() => setLoad(true)}
        ></iframe>
      </div>
    </>
  );
};
