import React from "react";
import { Modal, ModalProps } from "../base";
import { useTranslation } from "react-i18next";
import { TOrderType } from "../../../types";
import { Button } from "../../button";

import { TMessageType } from "./types";
import { IconsBasedOnMessageType } from "./constants";

interface IProps extends ModalProps {
  orderType: TOrderType;
  messageType: TMessageType;
}

export const HelpMessageModal: React.FC<IProps> = ({
  orderType,
  messageType,
  ...rest
}) => {
  const Icon = IconsBasedOnMessageType[messageType];
  const { t } = useTranslation("modals");
  return (
    <Modal {...rest}>
      <div className="m-auto text-5xl text-primary">
        <Icon />
      </div>
      <p className="mt-2 text-xl text-gray-700 text-center font-bold break-words w-full">
        {t(`message.${orderType}.text`)}
      </p>
      <p className="text-center text-xs md:text-base text-gray-400">
        {t(`message.${orderType}.hint`)}
      </p>
      <div className="mt-3 m-auto">
        <Button onClick={rest.closeModal} size="l">
          {t("common:understand")}
        </Button>
      </div>
    </Modal>
  );
};
