import React from "react";
import { TRestaurant } from "../types";

import { GiKnifeFork } from "react-icons/gi";
import { useTranslation } from "react-i18next";

interface IProps {
  cuisine: TRestaurant["cuisine"];
}

export const Cuisine: React.FC<IProps> = ({ cuisine }) => {
  const { i18n } = useTranslation();
  const translated = cuisine[i18n.language as "ru"];
  return (
    <div className="flex items-start text-lg sm:text-2xl lg:text-xl text-gray-500">
      <span className="w-7 sm:w-9 text-2xl flex flex-shrink-0 items-end justify-center">
        <GiKnifeFork />
      </span>
      <ul className="flex flex-wrap">
        {translated.map((item, idx) => {
          return (
            <li key={idx} className="space-x-2">
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
