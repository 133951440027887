// @ts-nocheck
import React from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGES_BY_FLAG, LANGUAGES } from "../../../constants";

import { LanguagesEnum } from "../../types";

import { MenuButton } from "../components/button";
import Cookies from "js-cookie";

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = React.useState<LanguagesEnum>(() => {
    return i18n.language as LanguagesEnum;
  });
  const changeLanguage = () => {
    let nextLng;
    const currentIndex = LANGUAGES.findIndex((lang) => lang === currentLang);
    if (currentIndex === LANGUAGES.length - 1) {
      nextLng = LANGUAGES[0];
    } else {
      nextLng = LANGUAGES[currentIndex + 1];
    }
    setCurrentLang(nextLng);
    i18n.changeLanguage(nextLng);
    Cookies.set("language", nextLng);
  };

  return (
    <MenuButton onClick={changeLanguage} style={{ lineHeight: "0" }}>
      {LANGUAGES_BY_FLAG[currentLang]}
    </MenuButton>
  );
};
