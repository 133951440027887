import { LanguagesEnum, TObject } from "./types";

export const LANGUAGES = ["ru", "en"];
export const DEFAULT_LANGUAGE = "ru";
export const LANGUAGES_BY_FLAG = {
  [LanguagesEnum["RU"]]: "🇷🇺",
  [LanguagesEnum["EN"]]: "🇺🇸",
};

export const scriptLanguageCodes = {
  ru: "ru_RU",
  en: "en_US",
};

export enum MapServiceType {
  "restaurant" = "Бронь ресторана",
  "service" = "Услуга",
  "shop" = "Магазин",
  "activity" = "Мероприятие",
}

export enum MapObjectType {
  "kustarny" = "Кустарный",
  "zagorodny" = "Загородный",
  "aparts" = "Апаратменты",
}

export const defaultObjects: TObject[] = ["aparts", "kustarny", "zagorodny"];

export const i18nPagesNamespace = "pages";
