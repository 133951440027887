import { defaultObjects } from "../constants";
import { TUser } from "../types";

export const isValidUser = (user: TUser | null) => {
  if (!user) {
    return false;
  }

  const isObjectValid = !!user.object && defaultObjects.includes(user.object);
  const isRoomValid = !!user.roomNumber && parseInt(user.roomNumber, 10) > 0;

  return isObjectValid || isRoomValid;
};
