import React from "react";
import { BackLink, Logo, Contacts, HomeLink, LanguageSwitcher } from "./parts";

export const Menu: React.FC = () => {
  return (
    <div className="flex fixed w-full z-50 bg-white shadow-lg animate__animated animate__fadeInDown">
      <div className="container mx-auto px-4 py-2 flex space-x-1.5 items-center">
        <div className="flex-1">
          <Logo />
        </div>
        <BackLink />
        <HomeLink />
        <LanguageSwitcher />
        <Contacts />
      </div>
    </div>
  );
};
