import { client } from "../../sanity";
import { TExcursion } from "./types";

const query = `*[_type == 'excursion']|order(orderRank){
  _id, title, path, excursions,
  "img": image.asset->url
}`;

export const fetchExcursions = () => {
  return client.fetch<TExcursion[]>(query);
};
