import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useUser } from "../../../contexts/user-context";
import { sendMessage } from "../../../lib/telegram";
import { events, sendEvent } from "../../../lib/yaMetrika";
import { TOrderType } from "../../../types";
import { Button } from "../../button";
import { Input } from "../../input";
import { SuccessMessage } from "./success-message";

interface IProps {
  type: TOrderType;
  serviceName: string;
  closeModal: () => void;
}

export const Form: React.FC<IProps> = ({ type, closeModal, serviceName }) => {
  const { t } = useTranslation("modals");
  const { user } = useUser();
  const [mobile, setMobile] = React.useState("");
  const [room, setRoom] = React.useState(user?.roomNumber || "");
  const [quantity, setQuantity] = React.useState(() => {
    if (type === "shop") {
      return 1;
    }
  });
  const [isError, setError] = React.useState(false);
  const [isSubmited, setSubmited] = React.useState(false);

  const onSuccess = () => {
    const event = events[type].accept;
    sendEvent(event);
    setSubmited(true);
  };

  const onError = () => {
    setError(true);
    setSubmited(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setError(false);
    if (isSubmited) {
      closeModal();
    } else {
      sendMessage(
        {
          type,
          object: user!.object!,
          room,
          mobile,
          quantity,
          name: serviceName,
        },
        { onSuccess, onError }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {!isSubmited ? (
        <div className="space-y-3">
          <InputMask
            mask="+7 (999) 999-99-99"
            alwaysShowMask={false}
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            type="tel"
          >
            <Input
              type="tel"
              label={t("confirm.fields.mobile")}
              value={mobile}
              autoFocus
            />
          </InputMask>
          <Input
            label={t("confirm.fields.room")}
            value={room}
            onChange={(e) => setRoom(e.target.value)}
            inputMode="decimal"
          />
          {type === "shop" && (
            <Input
              label={t("confirm.fields.quantity")}
              value={quantity}
              onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
              type="number"
            />
          )}
        </div>
      ) : (
        <SuccessMessage />
      )}
      <Button
        type="submit"
        size="l"
        disabled={!mobile || !room}
        className="mt-5 w-full"
      >
        {isSubmited ? t("confirm.submit.success") : t("confirm.submit.common")}
      </Button>
      {isError && (
        <p className="mt-4 text-red-500 text-center font-bold">
          {t("confirm.error.network")}
        </p>
      )}
    </form>
  );
};
