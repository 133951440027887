import React from "react";
import { Link } from "react-router-dom";
import { TMenuItem } from "../types";

export const MenuItem: React.FC<TMenuItem> = ({
  path,
  title,
  img,
  isLink = true,
  onClick,
}) => {
  const className =
    "overflow-hidden relative flex items-center justify-center w-full h-full p-2 bg-cover bg-center text-center font-bold text-xl max-h-96 sm:text-2xl md:text-3xl lg:text-4xl";
  const content = (
    <>
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-40 z-0"></div>
      <p className="text-white break-words z-10 w-full">{title}</p>
    </>
  );

  return isLink ? (
    <Link
      onClick={onClick}
      to={path as string}
      className={className}
      style={{ backgroundImage: `url(${img})` }}
    >
      {content}
    </Link>
  ) : (
    <div
      onClick={onClick}
      className={`${className} cursor-pointer`}
      style={{ backgroundImage: `url(${img})` }}
    >
      {content}
    </div>
  );
};
