import React from "react";
import { IconContext } from "react-icons";

export const MenuButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, ...rest }) => {
  return (
    <button
      {...rest}
      type="button"
      className="h-3/4 md:h-4/5 flex items-center justify-center p-2 text-2xl md:text-4xl bg-primary animate__animated animate__fadeInRight animate__faster"
    >
      <IconContext.Provider value={{ color: "white" }}>
        {children}
      </IconContext.Provider>
    </button>
  );
};
