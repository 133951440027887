import { client } from "../../sanity";
import { TObject } from "../../types";
import { TCategory, TService } from "./types";

export const categoriesQuery =
  '*[_type == "service.category" && $objectId in object]{..., "image": image.asset->url}';
export const serviceWithoutCategoryQuery =
  '*[_type == "services.service" && !defined(categories) && $objectId == object]{..., "image": image.asset->url}';

export const serviceQuery =
  '*[_type == "services.service" && $categoryId in categories[]._ref && $objectId == object]{..., "image": image.asset->url}';

export const fetchCategories = (objectId: TObject) => {
  return client.fetch<TCategory[]>(categoriesQuery, { objectId });
};

export const fetchServicesWithoutCategory = (objectId: TObject) => {
  return client
    .fetch<TService[]>(serviceWithoutCategoryQuery, { objectId })
    .then((data) => {
      return data.reduce<{
        byId: Record<string, TService>;
        allIds: Array<TService>;
      }>(
        (acc, service) => {
          acc.byId[service._id] = service;
          acc.allIds.push(service);

          return acc;
        },
        { byId: {}, allIds: [] }
      );
    });
};

export const fetchServicesByCategory = (
  categoryId: string,
  objectId: TObject
) => {
  return client.fetch<TService[]>(serviceQuery, { categoryId, objectId });
};
