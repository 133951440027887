import { TMenuItem } from "../../shared/menu/types";
import {
  excursions,
  activities,
  museums,
  restauratns,
  services,
  shop,
} from "../../assets/img/routes";

export const I18N_BASE = "menu";

export const MainMenuItems: TMenuItem[] = [
  { path: "/excursions", title: "excursions.title", img: excursions },
  { path: "/museums", title: "museums.title", img: museums },
  { path: "/shop", title: "shop.title", img: shop },
  { path: "/services", title: "services.title", img: services },
  { path: "/restaurants", title: "restaurants.title", img: restauratns },
  { path: "/activities", title: "activities.title", img: activities },
];
