const ru = {
  common: {
    back: "Назад",
    home: "Домой",
    reload: "Перезагрузить страницу",
    tryMore: "Попробовать еще раз",
    book: "Оформить",
    understand: "Понятно",
    close: "Закрыть",
    enroll: "Участвовать",
    wip: "Эта страница пока не готова ☹️",
    servicesIntroduce: "Администратор сможет ознакомить Вас с нашими услугами",
    servicesChat: "Написать администратору",
    empty: "Здесь пока пусто ☹️",
    login: "Войти",
    goHome: "На главную",
    contacts: {
      text: "Написать:",
      admin: "Администратор",
      head: "Управляющий",
      gen: "Генеральный директор",
    },
    error: {
      network: "Что-то пошло не так...",
    },
  },
  address: {
    kustarny: "Кустарный, 2Б",
    zagorodny: "Загородный, 39",
    aparts: "Апартаменты",
  },
  modals: {
    message: {
      restaurant: {
        text: "Вы получите специальное предложение и/или приветственный подарок в заведении при бронировании через нас",
        hint: "Наш администратор ознакомит Вас с акутальными предложенями",
      },
    },
    confirm: {
      fields: {
        mobile: "Телефон",
        room: "Номер комнаты",
        quantity: "Кол-во",
      },
      error: {
        network: "Ошибка. Попробуйте еще раз",
      },
      success: {
        message: "Мы уже работаем над вашим заказом ❤️",
      },
      submit: {
        common: "Подтвердить",
        success: "Хорошо",
      },
    },
  },
  menu: {
    restaurants: {
      title: "Рестораны и бары",
    },
    excursions: {
      title: "Экскурсии",
    },
    shop: {
      title: "Магазин",
    },
    museums: {
      title: "Музеи",
    },
    theaters: {
      title: "Театры",
    },
    services: {
      title: "Услуги",
    },
    activities: {
      title: "Мероприятия",
    },
  },
  pages: {
    welcome: {
      title: "Добро пожаловать в Санкт-Петербург и отель «Статский Советник»!",
    },
    excursions: {
      title: "Экскурсии",
    },
    restaurants: {
      tag: "Действует акция",
      book: "Забронировать",
    },
  },
  forms: {
    login: {
      fields: {
        object: {
          placeholder: "Выберите...",
          label: "Объект размещения",
        },
        room: {
          placeHolder: "204",
          label: "Номер комнаты",
        },
      },
    },
  },
};

export default ru;
