import { useEffect } from "react";
import { useLocation, useRoutes } from "react-router";
import { YMInitializer } from "react-yandex-metrika";
import { withTranslation } from "react-i18next";

import { QueryClientProvider } from "react-query";
import { queryClient } from "./query-client";

import { routes as appRoutes } from "./routes";
import { sendUrlChangeEvent } from "./lib/yaMetrika";
import { UserProvider } from "./contexts/user-context";

const App = () => {
  const location = useLocation();
  const routes = useRoutes(appRoutes);

  useEffect(() => {
    sendUrlChangeEvent(`${location.pathname}${location.search}`);
  }, [location]);

  window.plausible =
    window.plausible ||
    function () {
      (window.plausible.q = window.plausible.q || []).push(arguments);
    };

  return (
    <>
      <YMInitializer
        accounts={[parseInt(process.env.REACT_APP_YANDEX_METRIKA_COUNTER!, 10)]}
        options={{
          webvisor: true,
        }}
        version="2"
      />
      <QueryClientProvider client={queryClient}>
        <UserProvider>{routes}</UserProvider>
      </QueryClientProvider>
    </>
  );
};

export default withTranslation()(App);
