import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { HiHome } from "react-icons/hi";
import { MenuButton } from "../components/button";

export const HomeLink: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === "/") {
    return null;
  }

  const handleClick = () => {
    navigate("/", { replace: true });
  };

  return (
    <MenuButton onClick={handleClick}>
      <HiHome />
    </MenuButton>
  );
};
