import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUser } from "../../contexts/user-context";

export const RequireUser: React.FC = ({ children }) => {
  const { user } = useUser();
  const location = useLocation();

  if (!user?.object) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <>{children}</>;
};
