import React from "react";
import { useQuery } from "react-query";
import { useUser } from "../../../contexts/user-context";
import { EmptyResult } from "../../../shared/empty-result";
import { Loader } from "../../../shared/loader";
import { ConfirmModal } from "../../../shared/modals";
import { NetworkError } from "../../../shared/network-error";
import { fetchLoots } from "../api";
import { Loot } from "./loot";
interface IProps {
  id: string;
}

export const Category: React.FC<IProps> = ({ id }) => {
  const { user } = useUser();
  const { isFetching, isError, data, refetch } = useQuery(["loot", id], () =>
    fetchLoots(id, user!.object!)
  );

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [current, setCurrent] = React.useState("");

  const openModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  if (isFetching) {
    return <Loader />;
  }
  if (isError) {
    return <NetworkError handleError={refetch} />;
  }

  if (data!.length === 0) {
    return <EmptyResult />;
  }

  return (
    <>
      <div>
        <ul className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-2 lg:gap-4">
          {data!.map((loot) => {
            return (
              <li
                key={loot._id}
                className="shadow-md p-3 border-2 border-primary bg-white animate__animated animate__fadeIn"
              >
                <Loot
                  {...loot}
                  openModal={openModal}
                  setCurrent={setCurrent}
                  closeModal={closeModal}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <ConfirmModal
        confirmMessage=""
        isOpen={isModalOpen}
        onAfterClose={() => setCurrent("")}
        type="shop"
        serviceName={current}
        closeModal={() => closeModal()}
        onRequestClose={() => closeModal()}
      />
    </>
  );
};
