import React from "react";
import { MdClose } from "react-icons/md";

interface IProps {
  onClose: () => void;
}

export const ModalClose: React.FC<IProps> = (props) => {
  return (
    <button
      {...props}
      type="button"
      className="absolute top-0 right-0 text-3xl p-2 text-gray-500"
      onClick={props.onClose}
    >
      <MdClose />
    </button>
  );
};
