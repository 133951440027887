import React from "react";
import { TMenuItem } from "./types";
import { MenuItem } from "./item";

interface IProps {
  items: TMenuItem[];
  className?: string;
}

export const Menu: React.FC<IProps> = ({ items, className, children }) => {
  return (
    <ul
      className={`grid h-full w-full animate__animated animate__faster animate__fadeIn ${className}`}
    >
      {items.map((item, idx) => {
        return (
          <li
            key={item.id || idx}
            className={`rounded-lg menu-item w-full overflow-hidden`}
          >
            <MenuItem {...item} isLink={!!item.path} />
          </li>
        );
      })}
      {React.Children.map(children, (child, idx) => {
        return (
          <li
            key={idx}
            className={`rounded-lg menu-item w-full overflow-hidden`}
          >
            {child}
          </li>
        );
      })}
    </ul>
  );
};
