import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Route, Routes } from "react-router-dom";
import { Loader } from "../../shared/loader";
import { Menu } from "../../shared/menu";
import { TMenuItem } from "../../shared/menu/types";
import { NetworkError } from "../../shared/network-error";
import { fetchExcursions } from "./api";
import { Excursion } from "./excursion";

export const Excursions = () => {
  const { i18n } = useTranslation();
  const { isFetching, isError, data, refetch } = useQuery(
    "excursions",
    fetchExcursions
  );

  const menuItems: TMenuItem[] | undefined = React.useMemo(() => {
    return data?.map((item) => ({
      // @ts-ignore
      title: item.title[i18n.language],
      path: item.path.current,
      img: item.img,
    }));
  }, [data, i18n.language]);

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return <NetworkError handleError={refetch} />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          <Menu
            items={menuItems!}
            className="grid-cols-2 lg:grid-cols-3 gap-2"
          />
        }
      />
      {data!.map((excursionGroup) => {
        return (
          <Route
            path={excursionGroup.path.current}
            element={<Excursion urls={excursionGroup.excursions} />}
          />
        );
      })}
    </Routes>
  );
};
