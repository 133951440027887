import React from "react";
import { useQuery } from "react-query";
import { fetchCategories } from "./api";

import { Loader } from "../../shared/loader";
import { NetworkError } from "../../shared/network-error";
import { Route, Routes } from "react-router";
import { Menu } from "../../shared/menu";
import { useTranslation } from "react-i18next";
import { Category } from "./components/category";
import { EmptyResult } from "../../shared/empty-result";
import { useUser } from "../../contexts/user-context";

export const Shop: React.FC = () => {
  const { user } = useUser();
  const { i18n } = useTranslation();
  const { isFetching, isError, data, refetch } = useQuery("categories", () =>
    fetchCategories(user!.object!)
  );

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return <NetworkError handleError={refetch} />;
  }

  if (data?.length === 0) {
    return <EmptyResult />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          <div className="flex h-3/4 xl:h-1/2 m-auto items-center justify-center">
            <Menu
              className="grid-cols-2 xl:grid-cols-4 gap-2"
              items={data!.map((category) => {
                return {
                  path: category.path.current,
                  img: category.img,
                  title: category.title[i18n.language as "ru"],
                };
              })}
            />
          </div>
        }
      />
      {data!.map((category) => {
        return (
          <Route
            key={category._id}
            path={category.path.current}
            element={<Category id={category._id} />}
          />
        );
      })}
    </Routes>
  );
};
