import React from "react";
import { useTranslation } from "react-i18next";
import ym from "react-yandex-metrika";
import { Button } from "../../shared/button";
import { TRestaurant } from "./types";
import { Address, Cuisine, Price, Tag, Schedule } from "./components";

interface IProps extends TRestaurant {
  openModal: () => void;
  closeModal: () => void;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
}

export const Restaurant: React.FC<IProps> = React.memo(
  ({
    title,
    address,
    avg_price,
    cuisine,
    description,
    menu,
    img,
    schedule,
    openModal,
    setCurrent,
  }) => {
    const { i18n, t } = useTranslation("pages");

    const handleClick = () => {
      ym("reachGoal", "book-restaurant", { name: title.ru });
      setCurrent(title.ru);
      openModal();
    };

    return (
      <div className="flex flex-col lg:flex-row h-full">
        <div className="flex relative mb-3 lg:mb-0 flex-shrink-0 lg:max-w-md xl:max-w-lg 2xl:max-w-2xl max-h-96">
          <img src={img} alt="Ресторан" className="object-cover" />
          <Tag>{t("restaurants.tag")}</Tag>
        </div>
        <div className="flex flex-col h-full lg:ml-4">
          <p className="sm:mb-2 text-3xl sm:text-4xl lg:text-4xl font-bold">
            {title[i18n.language as "ru"]}
          </p>
          <Address address={address} />
          <Cuisine cuisine={cuisine} />
          <Price price={avg_price} />
          <Schedule schedule={schedule} />
          <p className="flex my-2 items-center text-lg sm:text-xl xl:text-2xl text-gray-700 lg:text-xl">
            {description[i18n.language as "ru"]}
          </p>
          <Button
            variant="primary"
            size="l"
            onClick={handleClick}
            className="mt-auto"
          >
            {t("restaurants.book")}
          </Button>
        </div>
      </div>
    );
  }
);
