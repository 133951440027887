import React from "react";
import { useQuery } from "react-query";
import { fetchRestaurants } from "./api";

import { Loader } from "../../shared/loader";
import { NetworkError } from "../../shared/network-error";
import { Restaurant } from "./restaurant";
import { ConfirmModal } from "../../shared/modals";
import { HelpMessageModal } from "../../shared/modals/help-message";
import { EmptyResult } from "../../shared/empty-result";

export const Restaurants: React.FC = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isMessageOpen, setMessageOpen] = React.useState(true);
  const [current, setCurrent] = React.useState("");

  const openModal = React.useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setModalOpen(false);
  }, []);

  const { isFetching, isError, data, refetch } = useQuery(
    "restaurants",
    fetchRestaurants
  );

  if (isFetching) {
    return <Loader />;
  }

  if (isError) {
    return <NetworkError handleError={refetch} />;
  }

  if (data!.length === 0) {
    return <EmptyResult />;
  }

  return (
    <div className="flex items-center justify-center h-full">
      <ul className="grid grid-cols-1  gap-5">
        {data?.map((restaurant, idx) => {
          return (
            <li
              key={restaurant._id}
              className={`p-2 lg:p-4 border-4 border-primary shadow-xl bg-white animate__animated animate__faster animate__fadeInLeft animate__delay-${
                idx * 1
              }s`}
            >
              <Restaurant
                openModal={openModal}
                closeModal={closeModal}
                setCurrent={setCurrent}
                {...restaurant}
              />
            </li>
          );
        })}
      </ul>
      <ConfirmModal
        confirmMessage=""
        isOpen={isModalOpen}
        onAfterClose={() => setCurrent("")}
        type="restaurant"
        serviceName={current}
        closeModal={() => closeModal()}
      />
      <HelpMessageModal
        isOpen={isMessageOpen}
        orderType="restaurant"
        messageType="gift"
        closeModal={() => setMessageOpen(false)}
      />
    </div>
  );
};
