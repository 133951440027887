import Cookies from "js-cookie";
import { TUser } from "../../types";

export const saveUserToCookie = (user: TUser) => {
  Cookies.set("user", JSON.stringify(user), {
    expires: 1,
  });
};

export const getUserFromCookie = () => {
  try {
    const user = Cookies.get("user");
    if (user) {
      return JSON.parse(user);
    }
  } catch (error) {}
};

export const removeUserFromCookie = () => {
  Cookies.remove("user");
};
