import React from "react";
import { Modal, ModalProps } from "../base";
import { Form } from "./form";

interface IProps extends ModalProps {
  confirmMessage: string;
  type: "restaurant" | "service" | "shop" | "activity";
  serviceName: string;
  serviceDescription?: string;
}

export const ConfirmModal: React.FC<IProps> = ({
  confirmMessage,
  closeModal,
  serviceName,
  type,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      closeTimeoutMS={300}
      preventScroll={true}
      shouldCloseOnOverlayClick={true}
      closeModal={closeModal}
    >
      <Form type={type} closeModal={closeModal} serviceName={serviceName} />
    </Modal>
  );
};
