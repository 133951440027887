import React from "react";
import { useQuery } from "react-query";
import { fetchCategories, fetchServicesWithoutCategory } from "./api";
import { Loader } from "../../shared/loader";
import { NetworkError } from "../../shared/network-error";
import { Route, Routes } from "react-router";
import { Menu } from "../../shared/menu";
import { useTranslation } from "react-i18next";
import { Category } from "./components/category";
import { useUser } from "../../contexts/user-context";
import { EmptyResult } from "../../shared/empty-result";
import { MenuItem } from "../../shared/menu/item";
import { LootModal } from "./components/loot-modal";
import { ConfirmModal } from "../../shared/modals";

export const Services: React.FC = () => {
  const { user } = useUser();
  const { i18n } = useTranslation("common");
  const [current, setCurrent] = React.useState<string>(null!);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isOrderModalOpen, setOrderModalOpen] = React.useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrent("");
  };

  const order = () => {
    setOrderModalOpen(true);
  };

  const {
    isFetching,
    isError,
    data: categoriesData,
    refetch: refetchCategories,
  } = useQuery("services.categories", () => fetchCategories(user!.object!));

  const {
    isFetching: isServicesLoading,
    isError: isServicesError,
    data: services,
    refetch: refetchServices,
  } = useQuery("services.servicesNoCategory", () =>
    fetchServicesWithoutCategory(user!.object!)
  );

  const refetch = () => {
    if (isError) {
      if (isServicesError) {
        refetchCategories();
        refetchServices();
      } else {
        refetchCategories();
      }
    } else {
      if (isServicesError) {
        refetchServices();
      }
    }
  };

  if (isFetching || isServicesLoading) {
    return <Loader />;
  }
  if (isError || isServicesError) {
    return <NetworkError handleError={refetch} />;
  }
  if (categoriesData!.length === 0 && services?.allIds.length === 0) {
    return <EmptyResult />;
  }

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <Menu
              className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2"
              items={categoriesData!.map((menuItem) => {
                return {
                  id: menuItem._id,
                  path: menuItem.path.current,
                  img: menuItem.image,
                  title: menuItem.title[i18n.language as "ru"],
                };
              })}
            >
              {services?.allIds.map((service) => {
                return (
                  <MenuItem
                    id={service._id}
                    img={service.image}
                    title={service.title[i18n.language as "ru"]}
                    isLink={false}
                    onClick={() => {
                      setCurrent(service._id);
                      openModal();
                    }}
                  />
                );
              })}
            </Menu>
          }
        />
        {categoriesData!.map((category) => {
          return (
            <Route
              key={category._id}
              path={category.path.current}
              element={<Category id={category._id} />}
            />
          );
        })}
      </Routes>
      <LootModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        service={services!.byId[current]}
        order={order}
      />
      {current && (
        <ConfirmModal
          confirmMessage=""
          isOpen={isOrderModalOpen}
          type="service"
          serviceName={services!.byId[current].title.ru}
          closeModal={() => setOrderModalOpen(false)}
          onRequestClose={() => setOrderModalOpen(false)}
        />
      )}
    </>
  );
};
