import React from "react";
import queryString from "query-string";
import { TObject, TUser } from "../types";
import { useLocation, useNavigate } from "react-router";
import { saveUserToCookie } from "../features/login/lib";
import { defaultObjects } from "../constants";
import { isValidUser } from "../lib/user";

export const useQueryUser = (setter: (arg: TUser) => void) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { object, room } = queryString.parse(location.search);
  const isObject = !!object && defaultObjects.includes(object as TObject);
  const isRoom = !!room && parseInt(room as string, 10) !== 0;

  React.useEffect(() => {
    const user: TUser = {
      object: isObject ? (object as TObject) : null,
      roomNumber: isRoom ? (room as string) : null,
    };
    if (isValidUser(user)) {
      setter(user);
      saveUserToCookie(user);
      navigate(location.pathname, { replace: true });
    }
  }, [isObject, isRoom, location.pathname, navigate, object, room, setter]);
};
