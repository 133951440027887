import React from "react";
import { TRestaurant } from "../types";

interface IProps {
  price: TRestaurant["avg_price"];
}

export const Price: React.FC<IProps> = ({ price }) => {
  return (
    <div className="flex items-center text-xl sm:text-2xl lg:text-xl color text-gray-500">
      <span className="w-7 sm:w-9 text-2xl flex flex-shrink-0 justify-center">
        ₽
      </span>
      {price}
    </div>
  );
};
