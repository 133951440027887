import { RouteObject } from "react-router";
import { RequireUser } from "./features/require-user";
import { MainLayout } from "./layouts/main";
import {
  MainPage,
  MuseumsPage,
  ExcursionsPages,
  RestaurantsPage,
  ServicesPage,
  ShopPage,
  ChatPage,
  ActivitiesPage,
  LoginPage,
} from "./pages";

export const routes: RouteObject[] = [
  {
    path: "/chat",
    element: <MainLayout fullpage />,
    children: [{ index: true, element: <ChatPage /> }],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        index: true,
        element: <MainPage />,
      },
      {
        path: "/excursions/*",
        element: <ExcursionsPages />,
      },
      {
        path: "/museums/*",
        element: <MuseumsPage />,
      },
      {
        path: "/shop/*",
        element: (
          <RequireUser>
            <ShopPage />
          </RequireUser>
        ),
      },
      {
        path: "/services/*",
        element: (
          <RequireUser>
            <ServicesPage />
          </RequireUser>
        ),
      },
      {
        path: "/restaurants/*",
        element: <RestaurantsPage />,
      },
      {
        path: "/activities/*",
        element: <ActivitiesPage />,
      },
    ],
  },
];
