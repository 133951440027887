import React from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { IoArrowBackOutline } from "react-icons/io5";
import { MenuButton } from "../components/button";

export const BackLink: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const depth = location.pathname.split("/").length;

  if ((depth < 3 && !from) || location.pathname === "/login") {
    return null;
  }

  const handleClick = () => {
    if (from && location.pathname === "/chat") {
      navigate(from, { replace: true });
    } else {
      navigate(-1);
    }
  };

  return (
    <MenuButton onClick={handleClick}>
      <IoArrowBackOutline />
    </MenuButton>
  );
};
