import { client } from "../../sanity";
import { TRestaurant } from "./types";

export const query = `
*[_type == 'restaurant']|order(orderRank){
  _id, title, address, schedule,
  avg_price, cuisine, description,
  "menu": menu.asset->url,
  "img": image.asset->url
}
`;

export const fetchRestaurants = () => {
  return client.fetch<TRestaurant[]>(query);
};
