import React from "react";
import { TService } from "../types";
import { Modal, ModalProps } from "../../../shared/modals/base";
import { useTranslation } from "react-i18next";
import { Button } from "../../../shared/button";

interface IProps extends ModalProps {
  service: TService | "";
  order: () => void;
}

export const LootModal: React.FC<IProps> = ({ service, order, ...rest }) => {
  const { i18n, t } = useTranslation("common");
  if (!service) {
    return null;
  }
  return (
    <Modal {...rest}>
      <div className="flex flex-col">
        <img
          src={service.image}
          className="w-4/6 rounded-lg max-h-72 mx-auto"
          alt={service.title[i18n.language as "ru"]}
        />
        <p className="text-2xl text-center font-bold">
          {service.title[i18n.language as "ru"]}
        </p>
        <p className="text-2xl text-center">{service.price} ₽</p>
        {service.description && (
          <p className="text-gray-500 text-sm">
            {service.description[i18n.language as "ru"]}
          </p>
        )}
        <Button onClick={order} className="mt-5">
          {t("book")}
        </Button>
      </div>
    </Modal>
  );
};
