import React from "react";
import { getUserFromCookie } from "../features/login/lib";
import { useQueryUser } from "../hooks/useQueryUser";
import { TUser } from "../types";

type TUserContext = {
  user: TUser | null;
  setUser: (user: TUser) => void;
};

const UserContext = React.createContext<TUserContext>(null!);

export const useUser = () => {
  return React.useContext(UserContext);
};

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<TUser>(() => {
    return getUserFromCookie() || null;
  });

  useQueryUser(setUser);

  const value = { user, setUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
