export const getIdsFromUrls = (urls: string[]): string => {
  return urls
    .map((url) => {
      const match = url.match(/(?:\/activities\/)(\d*)(?:-)/);
      if (match) {
        return match[1];
      } else {
        return null;
      }
    })
    .join(",");
};
