import React from "react";
import { TRestaurant } from "../types";

import { MdSchedule } from "react-icons/md";

interface IProps {
  schedule: TRestaurant["schedule"];
}

export const Schedule: React.FC<IProps> = ({ schedule }) => {
  return (
    <div className="flex items-center text-xl sm:text-2xl text-gray-500">
      <span className="w-7 sm:w-9 text-2xl flex flex-shrink-0 items-end justify-center">
        <MdSchedule />
      </span>
      {schedule.from} - {schedule.to}
    </div>
  );
};
