import { TObject, TOrderType } from "../types";
import { MapObjectType, MapServiceType } from "../constants";
import axios from "axios";

type TMessageParams = {
  object: TObject;
  type: TOrderType;
  quantity?: number;
  name: string;
  mobile: string;
  room: string;
};

const getUrl = (message: string) =>
  `https://api.telegram.org/bot1959779367:AAEbdIC7JxpGLr0coyni4QxDvdXOxXz9BcE/sendMessage?chat_id=-1001571266142&text=${message}&parse_mode=markdown`;

export const encodeMessage = ({
  object,
  type,
  name,
  quantity,
  mobile,
  room,
}: TMessageParams) => {
  const message = `*Объект*: ${MapObjectType[object]}\n*Тип*: ${
    MapServiceType[type]
  }\n*Название*: ${name}\n${
    quantity ? `*Кол-во*: ${quantity}\n` : ""
  }*Телефон*: ${mobile}\n*Номер*: ${room}
  `;

  const encoded = encodeURI(message);

  return encoded;
};

export const sendMessage = async (
  params: TMessageParams,
  options?: {
    onSuccess?: () => void;
    onError?: () => void;
  }
) => {
  const message = encodeMessage(params);
  const url = getUrl(message);

  await axios.get(url).then(options?.onSuccess).catch(options?.onError);
};
