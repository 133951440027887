import React from "react";
import { useUser } from "../../contexts/user-context";
import { DropDown } from "../../shared/dropdown";
import { Button } from "../../shared/button";
import { TObject } from "../../types";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { saveUserToCookie } from "./lib";
import { isValidUser } from "../../lib/user";

type TOption = { value: TObject; label: string };

const options: TOption[] = [
  { value: "kustarny", label: "address:kustarny" },
  { value: "zagorodny", label: "address:zagorodny" },
  { value: "aparts", label: "address:aparts" },
];

export const Login: React.FC = () => {
  const { t } = useTranslation("forms");
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  React.useEffect(() => {
    if (isValidUser(user)) {
      navigate(from, { replace: true });
    }
  }, [from, navigate, user]);

  const [object, setObject] = React.useState<TOption | undefined>(() => {
    if (user?.object) {
      const current = options.find((option) => option.value === user.object);
      if (current) {
        return { ...current, label: t(current.label) };
      }
    }
  });

  const handleChange = (value: TOption) => {
    setObject(value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const user = {
      object: object!.value,
      roomNumber: null,
    };
    setUser(user);
    saveUserToCookie(user);
    navigate(from, { replace: true });
  };

  return (
    <form
      className="flex flex-col m-auto w-full max-w-md bg-white p-5 rounded-lg space-y-3"
      onSubmit={handleSubmit}
    >
      <DropDown
        value={object}
        // @ts-ignore
        onChange={handleChange}
        options={options.map((option) => ({
          ...option,
          label: t(option.label),
        }))}
        label={t("login.fields.object.label")}
        placeholder={t("login.fields.object.placeholder")}
      />
      <Button size="l" disabled={!object} type="submit">
        {t("common:login")}
      </Button>
    </form>
  );
};
