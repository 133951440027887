import React from "react";
import { SpinnerCircular } from "spinners-react";
import { colors } from "../styles/variables";

export const Loader: React.FC = () => {
  return (
    <SpinnerCircular
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      size={80}
      thickness={100}
      speed={150}
      color={colors.primary}
      secondaryColor="rgba(0, 0, 0, 0.44)"
    />
  );
};
