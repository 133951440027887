import React from "react";
import { useTranslation } from "react-i18next";
import { MainMenuItems, I18N_BASE } from "../features/menu/constants";
import { Menu } from "../shared/menu";

export const MainPage: React.FC = () => {
  const { t } = useTranslation(I18N_BASE);
  const items = MainMenuItems.map((item) => ({
    ...item,
    title: t(item.title),
  }));

  return <Menu items={items} className="grid-cols-2 gap-2 lg:grid-cols-3" />;
};
