import React, { InputHTMLAttributes } from "react";

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  wrapperClassName?: string;
}

export const Input: React.FC<IProps> = ({
  label,
  wrapperClassName,
  ...rest
}) => {
  return (
    <label className={`flex flex-col ${wrapperClassName}`}>
      <span className="block mb-1 font-bold text-lg">{label}</span>
      <input
        className="border-2 rounded-sm text-lg border-gray-300 p-3 outline-none focus:border-primary transition-colors"
        {...rest}
      />
    </label>
  );
};
