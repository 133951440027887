import React from "react";

interface IProps {
  size?: "s" | "l";
  variant?: "primary" | "secondary" | "success" | "warning";
  isLoading?: boolean;
}

const handleSize = (size: IProps["size"]) => {
  switch (size) {
    case "s":
      return "text-lg sm:text-xl xl:text-2xl p-2";
    case "l":
      return "text-xl sm:text-2xl xl:text-3xl p-3";
    default:
      return "text-lg sm:text-xl xl:text-2xl p-2";
  }
};

const handleBgColor = (variant: IProps["variant"]) => {
  switch (variant) {
    case "primary":
      return "bg-primary text-white hover:bg-primary-hover";
    case "secondary":
      return "bg-gray-400 text-white hover:bg-gray-600";
    case "success":
      return "bg-green-500 text-white hover:bg-green-600";
    case "warning":
      return "bg-red-500 text-white hover:bg-red-600";
    default:
      return "bg-primary text-white";
  }
};

export const Button: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement> & IProps
> = ({
  type = "button",
  children,
  className,
  size,
  variant,
  isLoading,
  ...rest
}) => {
  const bgClassName = handleBgColor(variant);
  const sizeClassName = handleSize(size);
  return (
    <button
      {...rest}
      type={type}
      className={`font-bold transition-colors ${sizeClassName} ${bgClassName} ${className} disabled:bg-gray-300 disabled:cursor-not-allowed`}
    >
      {children}
    </button>
  );
};
