import React from "react";
import { Menu } from "../shared/navigation";
import { Outlet } from "react-router";

interface IProps {
  fullpage?: boolean;
}

export const MainLayout: React.FC<IProps> = ({ fullpage = false }) => {
  const className = fullpage
    ? "flex-1 mt-20"
    : "container flex flex-col flex-1 mx-auto p-4 mt-20";
  return (
    <>
      <Menu />
      <div className={className}>
        <Outlet />
      </div>
    </>
  );
};
