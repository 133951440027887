import React from "react";

import { BsFillChatDotsFill } from "react-icons/bs";

import { MenuButton } from "../components/button";
import { useNavigate, useLocation } from "react-router";

export const Contacts: React.FC = () => {
  const { pathname } = useLocation();
  const navigatge = useNavigate();
  return (
    <>
      <MenuButton
        onClick={() =>
          navigatge("/chat", { state: { from: pathname }, replace: true })
        }
      >
        <BsFillChatDotsFill />
      </MenuButton>
    </>
  );
};
