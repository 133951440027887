import React from "react";
import { useTranslation } from "react-i18next";

import { BiError } from "react-icons/bi";
import { Button } from "./button";

interface IProps {
  message?: string;
  handleError: () => void;
}

export const NetworkError: React.FC<IProps> = ({ message, handleError }) => {
  const { t } = useTranslation("common");
  return (
    <div className="flex flex-col items-center m-auto text-3xl md:text-4xl xl:text-6xl text-center text-gray-600">
      <div className="text-red-500 text-6xl xl:text-7xl">
        <BiError />
      </div>
      {message ?? t("error.network")}
      <Button onClick={handleError} variant="primary" size="l" className="mt-5">
        {t("tryMore")}
      </Button>
    </div>
  );
};
