import React from "react";
// import { useQuery } from "react-query";
// import styled from "styled-components";
import { EmptyResult } from "../../shared/empty-result";
// import { Loader } from "../../shared/loader";
// import { ConfirmModal } from "../../shared/modals/confirm";
// import { NetworkError } from "../../shared/network-error";
// import { interactiveColors } from "../../styles/variables";
// import { fetchActivities } from "./api";
// import { Activity } from "./components/activity";

// const List = styled.ul`
//   margin: 0;
//   padding: 0;
//   list-style: none;
// `;

// const Item = styled.li`
//   border: solid ${interactiveColors.$primary};
//   padding: 10px;
//   background-color: white;
//   box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
//     rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
//   & + & {
//     margin-top: 15px;
//   }
// `;

// export const Activities: React.FC = () => {
//   const [isModalOpen, setModalOpen] = React.useState(false);
//   const [current, setCurrent] = React.useState("");

//   const openModal = React.useCallback(() => {
//     setModalOpen(true);
//   }, []);

//   const closeModal = React.useCallback(() => {
//     setModalOpen(false);
//   }, []);

//   const { isLoading, isError, data } = useQuery("activities", fetchActivities, {
//     staleTime: 1800000,
//   });

//   if (isLoading) {
//     return <Loader />;
//   }
//   if (isError) {
//     return <NetworkError />;
//   }

//   if (data?.length === 0) {
//     return <EmptyResult />;
//   }

//   return (
//     <>
//       <List>
//         {data?.map((activity, idx) => {
//           return (
//             <Item key={activity._id}>
//               <Activity
//                 setCurrent={setCurrent}
//                 openModal={openModal}
//                 {...activity}
//               />
//             </Item>
//           );
//         })}
//       </List>
//       <ConfirmModal
//         confirmMessage=""
//         isOpen={isModalOpen}
//         onAfterClose={() => setCurrent("")}
//         type="activity"
//         serviceName={current}
//         closeModal={() => closeModal()}
//         onRequestClose={() => closeModal()}
//       />
//     </>
//   );
// };

export const Activities: React.FC = () => {
  return <EmptyResult />;
};
