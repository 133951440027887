const en = {
  common: {
    back: "Back",
    home: "Home",
    reload: "Reload page",
    book: "Book",
    enroll: "Enroll",
    wip: "This page currently unavailable ☹️",
    understand: "Ok",
    close: "Close",
    servicesIntroduce:
      "The administrator will be able to acquaint you with our services",
    servicesChat: "Contact the administrator",
    login: "Login",
    empty: "There is no data ☹️",
    contacts: {
      text: "Message to:",
      admin: "Administrator",
      head: "Manager",
      gen: "General manager",
    },
    error: {
      network: "Something went wrong ☹️",
    },
  },
  address: {
    kustarny: "Kustrany, 2b",
    zagorodny: "Zagorodny, 39",
    aparts: "Apartments",
  },
  modals: {
    message: {
      restaurant: {
        text: "You will receive a special offer and / or a welcome gift at the establishment when you book through us",
        hint: "Our administrator will acquaint you with current offers",
      },
    },
    confirm: {
      fields: {
        mobile: "Mobile",
        room: "Room number",
        quantity: "Quantity",
      },
      error: {
        network: "Error. Try one more time",
      },
      success: {
        message: "We are already working on your order ❤️",
      },
      submit: {
        common: "Accept",
        success: "Ok",
      },
    },
  },
  menu: {
    restaurants: {
      title: "Restaurants and bars",
    },
    excursions: {
      title: "Excursions",
    },
    museums: {
      title: "Museums",
    },
    shop: {
      title: "Shop",
    },
    services: {
      title: "Services",
    },
    activities: {
      title: "Activities",
    },
  },
  pages: {
    excursions: {
      menu: {
        city: { title: "City / city view / Groups" },
        suburban: { title: "Countryside" },
        night: { title: "Night city" },
        author: { title: "Authority" },
        individual: { title: "Individual" },
        river: { title: "По рекам и каналам" },
      },
    },
    restaurants: {
      book: "Book",
      tag: "Special offer",
    },
  },
  forms: {
    login: {
      fields: {
        object: {
          placeholder: "Select...",
          label: "Where are you stay",
        },
        room: {
          placeHolder: "204",
          label: "Room number",
        },
      },
    },
  },
};

export default en;
