import ReactModal, { Props as ReactModalProps } from "react-modal";
import noScroll from "no-scroll";
import { ModalClose } from "./components";

ReactModal.setAppElement("#root");

export interface ModalProps extends ReactModalProps {
  closeModal: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  children,
  closeModal,
  ...rest
}) => {
  return (
    <ReactModal
      {...rest}
      closeTimeoutMS={500}
      preventScroll={true}
      shouldCloseOnOverlayClick={true}
      className="modal animate__animated animate__faster animate__fadeInDown"
      overlayClassName="modal-overlay"
      onRequestClose={closeModal}
      onAfterOpen={() => {
        rest.onAfterOpen && rest.onAfterOpen();
        noScroll.on();
      }}
      onAfterClose={() => {
        rest.onAfterClose && rest.onAfterClose();
        noScroll.off();
      }}
    >
      <div className="flex flex-col w-full h-full">
        <ModalClose onClose={closeModal} />
        {children}
      </div>
    </ReactModal>
  );
};
