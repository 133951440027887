export enum LanguagesEnum {
  RU = "ru",
  EN = "en",
  ZH = "zh",
}

export type TObject = "kustarny" | "zagorodny" | "aparts";

export type TUser = {
  object: TObject | null;
  roomNumber: string | null;
};

export type TOrderType = "restaurant" | "service" | "shop" | "activity";
