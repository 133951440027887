import React from "react";
import { useTranslation } from "react-i18next";
import { TRestaurant } from "../types";
import { MdPlace } from "react-icons/md";

interface IProps {
  address: TRestaurant["address"];
}

export const Address: React.FC<IProps> = ({ address }) => {
  const { i18n } = useTranslation();
  const addrs = address[i18n.language as "ru"];

  return (
    <div className="flex items-start text-xl sm:text-2xl lg:text-2xl color text-gray-500">
      <span className="w-7 sm:w-9 text-3xl flex flex-shrink-0 items-end justify-center">
        <MdPlace />
      </span>
      {addrs}
    </div>
  );
};
