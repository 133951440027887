import React from "react";
import { useTranslation } from "react-i18next";
import { events, sendEvent } from "../../../lib/yaMetrika";
import { Button } from "../../../shared/button";
import { TLoot } from "../types";

interface IProps extends TLoot {
  openModal: () => void;
  closeModal: () => void;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
}

export const Loot: React.FC<IProps> = ({
  title,
  price,
  image,
  description,
  openModal,
  setCurrent,
}) => {
  const { i18n, t } = useTranslation("common");

  const handleClick = () => {
    sendEvent(events.shop.request, { name: title.ru });
    setCurrent(title.ru);
    openModal();
  };

  return (
    <div className="flex flex-col h-full">
      <div className="h-44 flex justify-center">
        <img
          src={image}
          alt={title[i18n.language as "ru"]}
          className="max-w-full h-full"
        />
      </div>
      <div className="flex flex-col h-full">
        <p className="text-3xl mt-2">{price} ₽</p>
        <p className="text-xl lg:text-2xl font-bold my-2">
          {title[i18n.language as "ru"]}
        </p>
        {description?.ru && (
          <p className="text-gray-400 text-base mb-2 lg:text-lg">
            {description[i18n.language as "ru"]}
          </p>
        )}
        <Button
          size="l"
          variant="primary"
          className="mt-auto"
          onClick={handleClick}
        >
          {t("book")}
        </Button>
      </div>
    </div>
  );
};
