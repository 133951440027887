import { client } from "../../sanity";
import { TObject } from "../../types";
import { TCategory, TLoot } from "./types";

export const categoriesQuery =
  '*[_type == "shop.category" && $objectId in object]{..., "img": image.asset->url}';

export const lootQuery =
  '*[_type == "shop.service" && $categoryId in categories[]._ref && $object == object]{..., "image": image.asset->url}';

export const fetchCategories = (objectId: TObject) => {
  return client.fetch<TCategory[]>(categoriesQuery, { objectId });
};

export const fetchLoots = (categoryId: string, object: TObject) => {
  return client.fetch<TLoot[]>(lootQuery, { categoryId, object });
};
