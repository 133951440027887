import React from "react";
import { FiPercent } from "react-icons/fi";

export const Tag: React.FC = ({ children }) => {
  return (
    <div className="absolute top-2 left-1 bg-red-500 opacity-90 rounded-2xl flex items-center p-1 text-white font-bold">
      <span className="flex mr-1 text-lg sm:text-xl">
        <FiPercent />
      </span>
      <p className="text-base">{children}</p>
    </div>
  );
};
